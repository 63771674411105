export enum LicenseStatusValue {
    NEW = 'new',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    EXPIRED = 'expired'
};

export enum MspLicenseType {
    TRIAL = 'trial',
    ONE_YEAR = 'one_year',
    TWO_YEARS = 'two_years',
    THREE_YEARS = 'three_years',
    FIVE_YEARS = 'five_years'
};

export type MspLicenseStatus = {
    [value in MspLicenseType]?: number;
};

export interface LicenseStatus extends MspLicenseStatus{
    monthly?: number;
};

export type MspLicenseNameMap = {
    [value in MspLicenseType]?: string;
};
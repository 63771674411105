import { MspLicenseNameMap, MspLicenseType } from "@core/types";

// Device License Action List
export const LICENSE_ACTION_LIST = {
	assign: {
		name: "Assign",
		icon: "icon-bookmark"
	},
	unclaim: {
		name: "Unclaim",
		icon: "icon-delete",
	},
	transfer: {
		name: "Transfer",
		icon: "icon-shuffle"
	},
	renew: {
		name: "Renew",
		icon: "icon-rotate-cw"
	},
	reclaim: {
		name: "Reclaim",
		icon: "icon-corner-left-up"
	}
	/* delete: {
		name: "Delete",
		icon: "icon-x-circle",
		alert: "Deleted license(s) will be gone forever. Are you sure you want to Delete the License(s)?"
	} */
};

// Device ISP Price Chart
export const TRIAL_PRICE_CHART = {
	'TRIAL-MO-5LP': { cost: 1501, total: 5 }
};

export const ISP_PRICE_CHART = {
	'ISP-MO-100LP': { cost: 125, total: 100 },
	'ISP-MO-200LP': { cost: 175, total: 200 },
	'ISP-MO-300LP': { cost: 250, total: 300 },
	'ISP-MO-400LP': { cost: 300, total: 400 },
	'ISP-MO-500LP': { cost: 350, total: 500 },
	'ISP-MO-1KLP': { cost: 400, total: 1000 },
	'ISP-MO-2KLP': { cost: 600, total: 2000 },
	'ISP-MO-3KLP': { cost: 750, total: 3000 },
	'ISP-MO-4KLP': { cost: 800, total: 4000 },
	'ISP-MO-5KLP': { cost: 850, total: 5000 },
	'ISP-MO-6KLP': { cost: 900, total: 6000 },
	'ISP-MO-7KLP': { cost: 950, total: 7000 },
	'ISP-MO-8KLP': { cost: 1000, total: 8000 },
	'ISP-MO-9KLP': { cost: 1150, total: 9000 },
	'ISP-MO-10KLP': { cost: 1250, total: 10000 },
	'ISP-MO-11KLP': { cost: 1501, total: 11000 },
	'ISP-MO-12KLP': { cost: 1502, total: 12000 },
	'ISP-MO-13KLP': { cost: 1503, total: 13000 },
	'ISP-MO-14KLP': { cost: 1504, total: 14000 },
	'ISP-MO-15KLP': { cost: 1505, total: 15000 },
	'ISP-MO-16KLP': { cost: 1506, total: 16000 },
	'ISP-MO-17KLP': { cost: 1507, total: 17000 },
	'ISP-MO-18KLP': { cost: 1508, total: 18000 },
	'ISP-MO-19KLP': { cost: 1509, total: 19000 },
	'ISP-MO-20KLP': { cost: 1510, total: 20000 },
	'ISP-MO-21KLP': { cost: 1511, total: 21000 },
	'ISP-MO-22KLP': { cost: 1512, total: 22000 },
	'ISP-MO-23KLP': { cost: 1513, total: 23000 },
	'ISP-MO-24KLP': { cost: 1514, total: 24000 },
	'ISP-MO-25KLP': { cost: 1515, total: 25000 }
};

export const ISP_PLUS_PRICE_CHART = {
	'ISP-MO-100LP': { cost: 150, total: 100 },
	'ISP-MO-200LP': { cost: 240, total: 200 },
	'ISP-MO-300LP': { cost: 300, total: 300 },
	'ISP-MO-400LP': { cost: 360, total: 400 },
	'ISP-MO-500LP': { cost: 420, total: 500 },
	'ISP-MO-1KLP': { cost: 480, total: 1000 },
	'ISP-MO-2KLP': { cost: 720, total: 2000 },
	'ISP-MO-3KLP': { cost: 900, total: 3000 },
	'ISP-MO-4KLP': { cost: 960, total: 4000 },
	'ISP-MO-5KLP': { cost: 1020, total: 5000 },
	'ISP-MO-6KLP': { cost: 1080, total: 6000 },
	'ISP-MO-7KLP': { cost: 1140, total: 7000 },
	'ISP-MO-8KLP': { cost: 1200, total: 8000 },
	'ISP-MO-9KLP': { cost: 1380, total: 9000 },
	'ISP-MO-10KLP': { cost: 1500, total: 10000 },
	'ISP-MO-11KLP': { cost: 1501, total: 11000 },
	'ISP-MO-12KLP': { cost: 1502, total: 12000 },
	'ISP-MO-13KLP': { cost: 1503, total: 13000 },
	'ISP-MO-14KLP': { cost: 1504, total: 14000 },
	'ISP-MO-15KLP': { cost: 1505, total: 15000 },
	'ISP-MO-16KLP': { cost: 1506, total: 16000 },
	'ISP-MO-17KLP': { cost: 1507, total: 17000 },
	'ISP-MO-18KLP': { cost: 1508, total: 18000 },
	'ISP-MO-19KLP': { cost: 1509, total: 19000 },
	'ISP-MO-20KLP': { cost: 1510, total: 20000 },
	'ISP-MO-21KLP': { cost: 1511, total: 21000 },
	'ISP-MO-22KLP': { cost: 1512, total: 22000 },
	'ISP-MO-23KLP': { cost: 1513, total: 23000 },
	'ISP-MO-24KLP': { cost: 1514, total: 24000 },
	'ISP-MO-25KLP': { cost: 1515, total: 25000 }
};

export const TENANT_ACCOUNT_TYPES = [
	{ name: "Enterprise(MSP/Partner)", value: "partner" },
	{ name: "Standard", value: "ISP" },
	{ name: "Plus", value: "ISP-Plus" },
	{ name: "Pro", value: "ISP-Pro" },
	{ name: "Trial", value: "trial" }
];

export const MSP_LICENSE_TYPE_NAME_MAP: MspLicenseNameMap = {
	[MspLicenseType.TRIAL]: "Trial",
	[MspLicenseType.ONE_YEAR]: "One Year",
	[MspLicenseType.TWO_YEARS]: "Two Years",
	[MspLicenseType.THREE_YEARS]: "Three Years",
	[MspLicenseType.FIVE_YEARS]: "Five Years",
};

export const MSP_LICENSE_COUNTING_NAME: Array<any> = [
	{ name: MSP_LICENSE_TYPE_NAME_MAP[MspLicenseType.TRIAL], key: "availableTrial", badgeClass: 'badge-light-danger' },
	{ name: MSP_LICENSE_TYPE_NAME_MAP[MspLicenseType.ONE_YEAR], key: "availableOneYear", badgeClass: 'badge-light-warning' },
	{ name: MSP_LICENSE_TYPE_NAME_MAP[MspLicenseType.TWO_YEARS], key: "availableTwoYears", badgeClass: 'badge-light-primary' },
	{ name: MSP_LICENSE_TYPE_NAME_MAP[MspLicenseType.THREE_YEARS], key: "availableThreeYears", badgeClass: 'badge-light-success' },
	{ name: MSP_LICENSE_TYPE_NAME_MAP[MspLicenseType.FIVE_YEARS], key: "availableFiveYears", badgeClass: 'badge-light-info' }
];
import { MspLicenseType } from "./license-status";

export interface licenseAssignToTenantRequest {
    licenseType: MspLicenseType | "monthly_subscription";
    tenantId: string;
    amount?: number;
};

export interface licenseActionToDeviceRequest {
    MAC_array?: string[];
    MAC?: string;
    licenseType?: 'monthly_subscription' | MspLicenseType | string;
    groupId?: string;
    action: 'assign' | 'unassign' | 'renew' | 'transfer'
};
